<script setup>
// Imports...
import SettingsGridView from '@/components/settings/GridLayout.vue';
import SettingsGa4Modal from '@/components/ui/modals/SettingsGa4Modal.vue';
import SettingsNotSyncedModal from '@/components/ui/modals/SettingsNotSyncedModal.vue';
import { useModalStore } from '@/stores/ModalStore';

// Logic...

const modalStore = useModalStore();
</script>

<template>
  <div class="styles_view__body">
    <SettingsGridView />
  </div>
  <!-- Modals -->
  <SettingsGa4Modal v-if="modalStore.isModalOpen('syncedGoogleAnalytics4Modal')" />
  <SettingsNotSyncedModal v-if="modalStore.isModalOpen('settingsNotSyncedModal')" />
  <!-- Modals End -->
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.section__body-alt {
  max-width: 2100px;
  margin: auto;
  padding: 178px 30px;

  @include mobile {
    padding: 178px 20px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(97, 112, 134, 0.25);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 5px;
  }
}
</style>
