import { icons, previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';
import { mediumWidget } from '../WidgetSizes';

const commonFields = {
  integration: 'Google Analytics 4',
  icon: icons.AppGa4Icon,
};

export const ga4Widgets = [
  {
    title: 'Average Session Time',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'AvgSessionTimeMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Bounce Rate',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'BounceRateMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Browsers',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    component: 'BrowsersDonut',
    i: uuidv4(),
    ...commonFields,
    ...mediumWidget,
  },
  {
    title: 'Campaign Traffic',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'CampaignTrafficTable',
    i: uuidv4(),
    ...commonFields,
    ...mediumWidget,
  },
  {
    title: 'Conversion Activity',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    component: 'ConversionActivityLine',
    i: uuidv4(),
    ...commonFields,
    ...largeWidget,
  },
  {
    title: 'Conversion Rate',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'ConversionRateMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Conversions',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'Ga4ConversionsMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Conversion Sources',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'ConversionSourcesTable',
    i: uuidv4(),
    ...commonFields,
    ...largeWidget,
  },
  {
    title: 'Devices',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    component: 'DevicesDonut',
    i: uuidv4(),
    ...commonFields,
    ...mediumWidget,
  },
  {
    title: 'Landing Pages',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'LandingPagesTable',
    i: uuidv4(),
    ...commonFields,
    ...mediumWidget,
  },
  {
    title: 'Pages Per Session',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'PagesPerSessionMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Percentage New Sessions',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'PercentageNewSessionsMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Sessions',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'SessionsMetric',
    i: uuidv4(),
    ...commonFields,
    ...smallWidget,
  },
  {
    title: 'Social Traffic',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'SocialTrafficTable',
    i: uuidv4(),
    ...commonFields,
    ...mediumWidget,
  },
  {
    title: 'Top Pages',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'TopPagesTable',
    ...commonFields,
    ...mediumWidget,
  },
  {
    title: 'Traffic Activity',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'TrafficActivityLine',
    ...commonFields,
    ...largeWidget,
  },
  {
    title: 'Traffic Sources',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'TrafficSourcesTable',
    ...commonFields,
    ...largeWidget,
  },
  {
    title: 'Trending Conversions',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'TrendingConversionsBar',
    ...commonFields,
    ...largeWidget,
  },
  {
    title: 'Trending Sessions',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'TrendingSessionsBar',
    ...commonFields,
    ...largeWidget,
  },
  {
    title: 'Visitor Locations',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'VisitorLocationsTable',
    ...commonFields,
    ...largeWidget,
  },
];
