import { icons, previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';
import { halfWidget, mediumWidget } from '../WidgetSizes';

const commonFields = {
  icon: icons.AppGBPIcon,
  integration: 'Google Business Profile',
};

export const gbpWidgets = [
  {
    title: 'Total Views',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'TotalViewsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Google Search',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'GoogleSearchMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Google Maps',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'GoogleMapsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Trending Views',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'TrendingViewsBar',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Activity',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    i: uuidv4(),
    component: 'ActivityDonut',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Listing Info',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'ListingInfoTable',
    ...largeWidget,
    ...commonFields,
  },
];
