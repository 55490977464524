<!-- DashboardsTopFilterSection.vue -->
<script setup>
// Imports...
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import { computed, onMounted, ref } from 'vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import AppPropertiesSelector from '@/components/ui/selects/AppPropertiesSelector.vue';
import AppDatePicker from '@/components/ui/AppDatePicker.vue';
import AppIconAdd from '@/components/svg/AppIconAdd.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import { useDashboardsStore } from '@/stores/DashboardsStore';
// Logic...
const globalStore = useGlobalStore();

const reset = ref(false);
// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};

const dashboardStore = useDashboardsStore();

onMounted(() => {
  dashboardStore.loadDashboardOptions();
});

const isDropdownOpen = ref(false);

const defaultOptions = [{ value: null, label: 'No dashboards available' }];

const dashboardOptions = computed(() => {
  return dashboardStore.dashboardOptions.length > 0 ? dashboardStore.dashboardOptions : defaultOptions;
});

const addDashboard = () => {
  dashboardStore.createNewDashboard();
  isDropdownOpen.value = false;
};
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Properties Filter -->
      <div class="flex-auto">
        <AppPropertiesSelector />
      </div>
      <!-- Dashboard Filter -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          bodyWidth="325px"
          title="Dashboard"
          placeholder="Select a dashboard"
          :show-search="true"
          :options="dashboardOptions"
          v-model="dashboardStore.selectedDashboard"
          v-model:open="isDropdownOpen"
        >
          <template v-slot:footer>
            <div class="footer-container">
              <div class="footer-item">
                <AppIconAdd />
                <a @click="addDashboard">New dashboard</a>
              </div>
            </div>
          </template>
        </AppSingleSelector>
      </AppVisibleFilterWrapper>

      <!-- Date Range Filter -->
      <AppVisibleFilterWrapper>
        <AppDatePicker />
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="reset === true">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton
          label="View"
          class="filter-button-bottom"
          :isLoading="dashboardStore.isLoading"
          @click="dashboardStore.loadLayout(dashboardStore.selectedDashboard)"
        />
      </div>
      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.footer-item {
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border: none;
  border-radius: 7px;
  display: flex;
  width: 100%;
  text-align: left;
  padding: 7px 42px 7px 8px;
  color: inherit;
  cursor: pointer;
}

.footer-item:hover {
  background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);

  & svg {
    fill: var(--primary-color);
  }
}

.grid {
  display: flex;
  align-items: center; // Vertically center items
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto; // Flex item can grow and shrink but is initially sized to content
}

.flex-basis {
  flex-basis: content;
}

.list-buttons {
  display: flex;
  align-items: flex-end;

  > li {
    display: inline-block;
    padding-left: 10px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.visible-mobile {
  display: none;

  /*  Tablet  */

  @include tablet {
    display: block;
  }
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px; // Adjust to match the height of the reset button
  visibility: hidden; // Hide the placeholder but keep its space
}

.filter-clear-all {
  visibility: visible; // Make the actual button visible
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7; // Adjust as needed
  }
}
</style>
