export const liveGroupData = [
  {
    id: 1,
    name: "Northeast",
    category_type: "Default",
    property_ids: [1136, 893],
  },
  {
    id: 2,
    name: "Dweck",
    category_type: "Default",
    property_ids: [648, 567],
    tags: "PORTFOLIO",
  },
  {
    id: 3,
    name: "Avenue5",
    category_type: "Default",
    property_ids: [870, 1118],
    tags: "PORTFOLIO",
  },
  {
    id: 10,
    name: "Low occupancy",
    category_type: "Default",
    property_ids: [764],
    tags: "SMART",
  },
  {
    id: 7,
    name: "Disabled",
    category_type: "Default",
    property_ids: [1193],
  },
  {
    id: 4,
    name: "Southeast",
    category_type: "Default",
    property_ids: [285, 764, 567],
  },
  {
    id: 6,
    name: "Arizona",
    category_type: "Default",
    property_ids: [1343, 1136],
  },
  {
    id: 8,
    name: "Demo",
    category_type: "Default",
    property_ids: [710, 1193],
  },
  {
    id: 9,
    name: "Lease up",
    category_type: "Default",
    property_ids: [1125],
  },
  {
    id: 5,
    name: "High exposure",
    category_type: "Default",
    property_ids: [1118],
    tags: "SMART",
  },
  
]

export const notificationsData = [
  {
    id: 1,
    icon: "ico-stat",
    title: "Alert Title 1",
    date: "Mar. 22 - Apr. 20",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non odio leo.",
    url: "/",
  },
  {
    id: 2,
    icon: "ico-stat",
    title: "Alert Title 2",
    date: "Mar. 22 - Apr. 20",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non odio leo.",
    url: "/",
  },
  {
    id: 3,
    icon: "ico-up",
    title: "Alert Title 3",
    date: "Mar. 22 - Apr. 20",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non odio leo.",
    url: "/",
  },
  {
    id: 4,
    icon: "ico-square",
    title: "Alert Title 4",
    date: "Mar. 22 - Apr. 20",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non odio leo.",
    url: "/",
  },
];

export const alertOrderByOptions = [
  {
    label: 'Recently added',
    value: 'Recent'
  },
  {
    label: 'Status',
    value: 'Status'
  },
  {
    label: 'Title',
    value: 'Title'
  }
]

export const alertOrderByOptionsType = [
  {
    label: 'Recent',
    value: 'date',
    key: 'date_added'
  },
  {
    label: 'Status',
    value: 'text',
    key: 'status'
  },
  {
    label: 'Title',
    value: 'text',
    key: 'name'
  }
]

export const alertTypeOptions = [
  {
    label: 'Availability',
    value: 'Availability'
  },
  {
    label: 'Budgets',
    value: 'Budgets'
  },
  {
    label: 'Facebook Ads',
    value: 'Facebook Ads'
  },
  {
    label: 'Google Ads',
    value: 'Google Ads'
  },
  {
    label: 'Google Analytics (GA4)',
    value: 'Google Analytics (GA4)'
  },
  {
    label: 'Google Analytics (UA)',
    value: 'Google Analytics (UA)'
  },
  {
    label: 'Leasing',
    value: 'Leasing'
  },
  {
    label: 'Recurring Reports',
    value: 'Recurring Reports'
  }
]

export const adminListOfActions = ["Lorem", "Lorem", "Lorem"];

export const adminSettingsOptions = [
  {
    label: 'Integrations',
    value: 'Integrations'
  },
  {
    label: 'Permissions',
    value: 'Permissions'
  },
  {
    label: 'Categories',
    value: 'Categories'
  },
  {
    label: 'Profile',
    value: 'Profile'
  }
]

export const adminPropertyOptions = [
  {
    label: '100 Prince',
    value: '100 Prince'
  },
  {
    label: '1000 West',
    value: '1000 West'
  },
  {
    label: '12 Central Square',
    value: '12 Central Square'
  },
  {
    label: 'Alexan Memorial',
    value: 'Alexan Memorial'
  },
  {
    label: 'Alexan River Oaks',
    value: 'Alexan River Oaks'
  },
  {
    label: 'Alta 801',
    value: 'Alta 801'
  }
]