<!-- AppPropertiesSelector.vue -->
<script setup>
// Imports...
import AppMultiSelector from "@/components/ui/selects/AppMultiSelector.vue";
import AppSearch from "@/components/ui/search/AppSearch.vue";
import {ref} from "vue";
import {propertyGroupOptions} from "@/data/properties";
import useSearch from "@/composables/useSearch";
import AppCheckboxButton from "@/components/ui/buttons/AppCheckboxButton.vue";
// Logic...
const {searchValue, filteredOptions} = useSearch(propertyGroupOptions);
const selectedOption = ref([]);
function handleSelectedOption(option) {
  const index = selectedOption.value.findIndex(cat => cat.id === option.id);

  if (index > -1) {
    selectedOption.value.splice(index, 1);
  } else {
    selectedOption.value.push(option);
  }
}

function isChecked(categoryId) {
  return selectedOption.value.some(prop => prop.id === categoryId);
}

</script>

<template>
  <AppMultiSelector title="Properties" v-model:selectedOptions="selectedOption" placeholder="Select one or more properties">
    <template v-slot:body>
      <AppSearch placeholder="Search..." v-model="searchValue" @click.stop />
        <ul class="list">
          <li class="list-item" v-for="category in filteredOptions" :key="category.id">
            <div class="list-item-contents-wrapper">
              <AppCheckboxButton
                  :label="category.name"
                  :is-check="true"
                  :is-checked="isChecked(category.id)"
                  @checkboxClicked="handleSelectedOption(category)"
              />
            </div>
          </li>
        </ul>
    </template>
  </AppMultiSelector>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
}

.list-item {
  // li styles
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}
</style>
