<!-- AppModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import AppIconClose from '@/components/svg/AppIconClose.vue';
import { useModalStore } from '@/stores/ModalStore';

const props = defineProps({
  title: { type: String, required: true },
  modalId: { type: String, required: true },
});

const modalStore = useModalStore();
const isModalOpen = computed(() => modalStore.isModalOpen(props.modalId));

const closeModal = () => {
  modalStore.closeModal(props.modalId);
};

const modalContentRef = ref(null);

onClickOutside(modalContentRef, () => closeModal());
</script>

<template>
  <dialog v-if="isModalOpen" class="modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div :ref="modalContentRef" class="modal-content" @click.stop>

      <!-- Modal header -->
      <div class="modal-header">
        <div class="modal-title-and-icon">
          <h4>{{ props.title }}</h4>
        </div>
        <div @click="closeModal">
          <AppIconClose />
        </div>
      </div>

      <!-- Modal Content -->
      <div class="modal-items">
        <slot name="modalBody"></slot>
      </div>

      <!-- Modal footer -->
      <div v-if="$slots.modalFooter" class="modal-footer">
        <slot name="modalFooter"></slot>
      </div>
    </div>
  </dialog>
</template>

<style lang="scss" scoped>
dialog {
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 30vh;
  left: calc(50% - 15rem);
  width: 30rem;
  background-color: transparent;
  z-index: 9999;
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: auto;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  min-width: 300px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1.677px solid var(--secondary-color);

  h4 {
    font-size: 20px;
    font-weight: 700;
  }

  svg.icon-color {
    width: 14px;
    height: 14px;
  }

  .modal-title-and-icon {
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.modal-content {
  width: 550px;
  background-color: var(--element-bg);
  filter: drop-shadow(-1px 49px 50px #000);
  position: relative;
  color: var(--primary-text-color);
  border: 1.677px solid var(--secondary-color);
  border-radius: 13px;

  .input-label {
    color: var(--primary-text-color);
  }
}

.modal-items {
  padding: 25px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  border-top: 1.677px solid var(--secondary-color);
  padding: 20px;

  .btn {
    display: inline-block;
    border: none;
    background-color: var(--primary-color);
    background: var(--primary-gradient);
    background-size: 100%;
    border-radius: 12px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.019em;
    padding: 13px 20px;
    min-width: 120px;
    min-height: 45px;
    transition: all 0.4s;
  }
}
</style>
