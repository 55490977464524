<!-- AppTooltip.vue -->
<script setup>
// Imports...
import { ref, reactive } from 'vue';
// Logic...
const showTooltip = ref(false);
const overlayPosition = reactive({ top: 0, left: 0 });

// Debounce the tooltip for better performance
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    executedFunction.timeout = timeout;
  };
}

function updateTooltipPosition(event) {
  requestAnimationFrame(() => {
    const tooltipElement = event.target.getBoundingClientRect();
    const tooltipHeight = 65;
    const margin = 10; // Margin between the tooltip and the icon
    overlayPosition.top = tooltipElement.top - tooltipHeight - margin + window.scrollY;
    overlayPosition.left = tooltipElement.left + window.scrollX;
    showTooltip.value = true;
  });
}

const debouncedUpdateTooltipPosition = debounce(updateTooltipPosition, 100);


const handleShowToolTip = (event) => {
  debouncedUpdateTooltipPosition(event);
};

const handleHideTooltip = () => {
  showTooltip.value = false;
  clearTimeout(debouncedUpdateTooltipPosition.timeout);
};

const hasTextContent = (slots) => slots.text && slots.text().length > 0;

</script>

<template>
  <div class="tooltip-container" @mouseover="handleShowToolTip" @mouseleave="handleHideTooltip">
    <!-- Default slot for the icon -->
    <slot></slot>
    <Teleport to="body">
      <div v-if="showTooltip && hasTextContent($slots)" class="tooltip-text" :style="{ top: overlayPosition.top + 'px', left: overlayPosition.left + 'px' }">
        <slot name="text"></slot>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.tooltip-container {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.tooltip-text {
  max-width: 160px;
  position: fixed;
  white-space: wrap;
  margin-right: 30px;
  padding: 5px;
  border-radius: 4px;
  background-color: black;
  color: white;
  font-size: 0.8rem;
  z-index: 3000;
}
</style>
