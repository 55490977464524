<!-- PropertiesPageHeader.vue -->
<script setup>
// imports
import AppIconGridview from '@/components/svg/AppIconGridview.vue';
import AppIconListView from '@/components/svg/AppIconListView.vue';
import { usePropertiesStore } from '@/stores/PropertiesStore';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';

import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';

// Logic ***********************

// Store instances
const propertiesStore = usePropertiesStore();

const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  // Handle the option clicked logic here
  console.log(`Option ${option.text} clicked`);
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <h3>Properties</h3>
      </div>
      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div class="properties-view-options-container">
          <AppIconGridview @click="propertiesStore.toggleView('grid')" :isActive="propertiesStore.currentPropertyView === 'grid'" />
          <AppIconListView @click="propertiesStore.toggleView('list')" :isActive="propertiesStore.currentPropertyView === 'list'" />
        </div>
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
            <AppOverlayMenu
              v-if="isOverlayVisible"
              @option-clicked="handleOptionClicked"
              :options="['new-property', 'export-to-csv', 'settings']"
              bodyWidth="160px"
            >
              <template #new-property>
                <div>New property</div>
              </template>
              <template #export-to-csv>
                <div>Export list to CSV</div>
              </template>
              <template #settings>
                <div>View preferences</div>
              </template>
            </AppOverlayMenu>
          </AppIconBoxWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

.properties-view-options-container {
  width: 70px;
  height: 32px;
  padding: 5px;
  gap: 22px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
  }
}
</style>
