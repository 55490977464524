<script setup>
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { adminPropertyOptions, adminSettingsOptions } from '@/data/multiUseData';
import { ref } from 'vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';

const selectedProperties = ref('');
const selectedSetting = ref('');
const reset = ref(false);

// Store instances
const globalStore = useGlobalStore();

// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Property Selector -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          title="Property"
          :options="adminPropertyOptions"
          v-model="selectedProperties"
          placeholder="Select a property"
          :show-search="true"
          footer-link="/properties"
          footer-text="New Property"
        />
      </AppVisibleFilterWrapper>
      <!-- Select Settings -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Settings" :options="adminSettingsOptions" v-model="selectedSetting" placeholder="Select a setting" />
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="reset === true">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton label="View" class="filter-button-bottom" />
      </div>
      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.grid {
  display: flex;
  align-items: center; // Vertically center items
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto; // Flex item can grow and shrink but is initially sized to content
}

.flex-basis {
  flex-basis: content;
}

.visible-mobile {
  display: none;

  /*  Tablet  */

  @include tablet {
    display: block;
  }
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px; // Adjust to match the height of the reset button
  visibility: hidden; // Hide the placeholder but keep its space
}

.filter-clear-all {
  visibility: visible; // Make the actual button visible
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7; // Adjust as needed
  }
}
</style>
