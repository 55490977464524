<!-- GoogleAnalyticsModal.vue -->
<script setup>
import { ref } from "vue";
import AppSingleSelector from "@/components/ui/selects/AppSingleSelector.vue";
import AppTextInput from "@/components/ui/AppTextInput.vue";
import AppModal from "@/components/ui/AppModal.vue";

const selectedType = ref("");

</script>

<template>
 <teleport to="body">
   <AppModal title="Google Analytics 4 Settings" modal-id="syncedGoogleAnalytics4Modal">
     <template #modalBody>
       <AppSingleSelector
         title="Account"
         placeholder="Select an option"
         :options="['Texas', 'California', 'Florida']"
         v-model="selectedType"
       />
       <AppSingleSelector
         title="Property"
         placeholder="Select an option"
         :options="['Texas', 'California', 'Florida']"
         v-model="selectedType"
       />
       <AppSingleSelector
         title="Property ID"
         placeholder="Select an option"
         :options="['Texas', 'California', 'Florida']"
         v-model="selectedType"
       />
       <AppSingleSelector
         title="Webstream"
         placeholder="Select an option"
         :options="['Texas', 'California', 'Florida']"
         v-model="selectedType"
       />
       <AppSingleSelector
         title="Page path"
         placeholder="Select an option"
         :options="['Texas', 'California', 'Florida']"
         v-model="selectedType"
       />
       <AppTextInput title="Goals" placeholder="Goals" />
     </template>
   </AppModal>
 </teleport>
</template>
