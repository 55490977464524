<!-- AppReportsTable.vue -->
<script setup>
import {computed, ref} from 'vue';
import SimpleBar from 'simplebar-vue';

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  borders: {
    type: Object,
    default: () => ({yAxis: true, xAxis: true})
  },
  sortable: {
    type: Boolean,
    default: false
  },
  columnStyles: {
    type: Object,
    default: () => ({})
  },
  rowStyles: {
    type: Object,
    default: () => ({})
  },
  hoverRows: {
    type: [Array, Boolean],
    default: false
  },
  hoverCells: {
    type: [Array, Boolean],
    default: false
  },
  excludeFirstColumn: {
    type: Boolean,
    default: true
  },
  tableId: {
    type: String,
    required: true
  },
  minWidth: {
    type: String,
    default: 'auto'
  },
});


// **************************************Logic for sorting *********************************************************
let sortColumn = ref('');
let sortDirection = ref('asc');
let sortIcon = ref('↕');   // New reactive variable for sort icon

const headers = computed(() => {
  return props.data && props.data.length > 0 ? Object.keys(props.data[0]) : [];
});

const processedData = computed(() => {
  const data = props.data.map(option => Object.values(option));
  return sortData(data);
});

const sortData = (data) => {
  if (!sortColumn.value) return data;
  const columnIndex = headers.value.indexOf(sortColumn.value);
  return [...data].sort((a, b) => {
    if (sortDirection.value === 'asc') {
      return a[columnIndex] > b[columnIndex] ? 1 : -1;
    } else {
      return a[columnIndex] < b[columnIndex] ? 1 : -1;
    }
  });
};

const changeSortColumn = (columnName) => {
  if (props.sortable) {
    if (sortColumn.value === columnName) {
      sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
      sortColumn.value = columnName;
      sortDirection.value = 'asc';
    }
    sortIcon.value = sortDirection.value === 'asc' ? '↑' : '↓';  // Update sort icon using Unicode characters
  }
};

const getSortIcon = (header) => {
  if (sortColumn.value === header) {
    return sortDirection.value === 'asc' ? '↑' : '↓';
  }
  return '↕';  // Return default icon for all other headers
};
// **************************************end**************************************

// **************************************Logic for borders *********************************************************
const getBorderStyle = (rowIndex, colIndex) => {
  let style = {};

  // Add borderBottom if borders.horizontal is true
  if (props.borders.xAxis) {
    style.borderBottom = `var(--border-size) solid var(--secondary-color)`;
  }

  // Add borderRight if borders.vertical is true
  if (props.borders.yAxis) {
    style.borderRight = `var(--border-size) solid var(--secondary-color)`;
  }

  // Remove borderRight for the last cell in each row
  if (colIndex === headers.value.length - 1) {
    style.borderRight = 'none';
  }

  // Remove borderBottom for the last row
  if (rowIndex === processedData.value.length - 1) {
    style.borderBottom = 'none';
  }

  return style;
};
// **************************************end**************************************

// **************************************Logic for hover effects**************************************
const getRowClass = (rowIndex) => {
  return Array.isArray(computedHoverRows.value) && computedHoverRows.value.includes(rowIndex) ? 'hover' : '';
};

const getCellClass = (rowIndex, colIndex) => {
  return computedHoverCells.value && computedHoverCells.value.find(cell => cell.row === rowIndex && cell.col === colIndex) ? 'cellHover' : '';
};

const computedHoverRows = computed(() => {
  if (typeof props.hoverRows === 'boolean' && props.hoverRows) {
    return Array.from({length: processedData.value.length}, (_, i) => i);
  }
  return props.hoverRows;
});

const computedHoverCells = computed(() => {
  if (typeof props.hoverCells === 'boolean' && props.hoverCells) {
    return Array.from({length: processedData.value.length}, (_, rowIndex) =>
      Array.from({length: headers.value.length}, (_, colIndex) =>
        ({row: rowIndex, col: colIndex})
      )
    ).flat();
  }
  return props.hoverCells;
});
// **************************************end**************************************

// **************************************Logic for dynamic styles**************************************

const getColumnStyle = (colIndex) => {
  return {
    minWidth: props.excludeFirstColumn && colIndex === 0 ? 'auto' : props.minWidth,
    ...(props.columnStyles && props.columnStyles[colIndex] ? props.columnStyles[colIndex] : {})
  };
};

const getRowStyle = (rowIndex) => {
  return props.rowStyles && props.rowStyles[rowIndex] ? props.rowStyles[rowIndex] : {};
};
// **************************************end**************************************


</script>

<template>
  <div class="parent-container">
    <!-- Table -->
    <div class="table-container">
      <SimpleBar>
        <div class="table">
          <!-- Header Row -->
          <div class="table-head">
            <div class="table-row">
              <div
                v-for="(header, index) in headers"
                :key="index"
                :style="{ ...getColumnStyle(index), ...getBorderStyle(-1, index) }"
                @click="changeSortColumn(header)"
                class="table-header"
              >
                <span>{{ header }} </span>
                <span class="sort-styles" v-if="props.sortable">{{ getSortIcon(header) }}</span>
              </div>
            </div>
          </div>
          <!-- Data Rows -->
          <div class="table-body" >
            <div
              v-for="(row, rowIndex) in processedData"
              :key="rowIndex"
              :class="getRowClass(rowIndex)"
              :style="getRowStyle(rowIndex)"
              class="table-row"
            >
              <!-- Cells -->
              <div
                v-for="(value, colIndex) in row"
                :key="colIndex"
                :class="getCellClass(rowIndex, colIndex)"
                :style="{ ...getColumnStyle(colIndex), ...getBorderStyle(rowIndex, colIndex) }"
                v-html="value"
                class="table-data"
              >
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/settings/mixins';
@import 'simplebar-vue/dist/simplebar.min.css';

.parent-container {
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 18px;
  width: 100%;
  overflow: hidden; // Hide overflow to maintain rounded border
}

.table-container {
  overflow-x: auto; // Enables horizontal scrolling
  width: 100%; // Take full width of .table
}

.table {
  display: table;
  width: 100%;
  min-width: 600px; // Adjust as needed
}

:deep(.simplebar-scrollbar::before) {
  background-color: var(--secondary-color);
}

.table-row {
  display: flex;
}

.table-head {
  display: block;
}

.table-header {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: var(--secondary-color);
  cursor: default;
  flex: 1;
  display: block;
  padding: 10px 0;
}

.table-body {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 0; /* Hide scrollbar by default */
    transition: width 0.5s ease, background-color 0.5s ease; /* Increase transition duration */
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1); /* Lighter color when not hovered over */
    transition: background 0.5s ease, width 0.5s ease; /* Increase transition duration */
    border-radius: 10px;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 7px; /* Thinner scrollbar */
      scrollbar-gutter: auto;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--secondary-color);
      border-radius: 7px;
    }
  }
}


.table-data {
  font-size: 16px;
  line-height: 1.27;
  text-align: center;
  font-weight: 400;
  transition: background-color 0.3s ease;
  flex: 1;
  padding: 15px 10px;
  text-wrap: none;
}

.sort-styles {
  font-size: 12px;
  margin-left: 10px;
  align-self: end;
  vertical-align: middle;
}

// Dynamic
.hover:hover {
  background-color: hsla(216, 16%, 45%, 0.10);
  transition: background-color 0.3s ease;
}

// Dynamic
.cellHover:hover {
  background-color: rgba(0, 180, 60, 0.25);
}

</style>
