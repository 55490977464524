<!-- ReportsPageHeader.vue -->
<script setup>
// Imports...
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';

// Logic...

const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  // Handle the option clicked logic here
  console.log(`Option ${option.text} clicked`);
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <h3>
          Report Name
          <div class="pill">Property and/or group names</div>
        </h3>
      </div>
      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
          </AppIconBoxWrapper>
          <AppOverlayMenu
            v-if="isOverlayVisible"
            @option-clicked="handleOptionClicked"
            :options="['edit-settings', 'make-recurring', 'export-to-csv', 'export-to-pdf', 'share-link']"
            bodyWidth="150px"
          >
            <template #edit-settings>
              <div>Edit settings</div>
            </template>
            <template #make-recurring>
              <div>Make recurring</div>
            </template>
            <template #export-to-csv>
              <div>Export to CSV</div>
            </template>
            <template #export-to-pdf>
              <div>Export to PDF</div>
            </template>
            <template #share-link>
              <div>Share link</div>
            </template>
          </AppOverlayMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pill {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 50px;
  margin: 0 10px;
  padding: 5px 13px;
  display: inline-block;
  font-size: 0.65em;
  color: var(--primary-text-color);
  display: inline;
}

@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';
</style>
