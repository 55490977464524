<!-- PropertiesCard.vue -->
<script setup>
// Imports...
import AppIconOccupancy from '@/components/svg/AppIconOccupancy.vue';
import AppIconSynced from '@/components/svg/AppIconSynced.vue';
import AppIconExposure from '@/components/svg/AppIconExposure.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import { computed, ref } from 'vue';
import { usePropertiesStore } from '@/stores/PropertiesStore';
import PropertyLinks from '@/components/properties/card-components/PropertyLinks.vue';
import { onClickOutside } from '@vueuse/core/index';
// Logic...
defineProps(['properties']);
const search = ref('');
const propertiesStore = usePropertiesStore();

const searchProperties = computed(() => {
  return propertiesStore.properties.filter((property) => {
    return property.attributes.name.toLowerCase().includes(search.value.toLowerCase());
  });
});

const showOverlay = ref(null); // null indicates no overlay is shown
const target = ref(null);
function toggleOverlay(propertyId) {
  showOverlay.value = showOverlay.value === propertyId ? null : propertyId;
}

onClickOutside(target, () => {
  if (showOverlay.value !== null) {
    showOverlay.value = null;
  }
});
</script>

<template>
  <!-- properties -->
  <div class="property-grid">
    <div v-for="property in searchProperties" :key="property.id" class="property-card">
      <div class="top-half">
        <div class="property-img">
          <img :src="property.attributes.avatar_url" alt="Image" class="image" />
          <div class="property-img-overlay"></div>
        </div>
        <div class="property-text">
          <span>{{ property.attributes.name }}</span>
          <span class="location"
            >City, State
            <!-- {{ property.attributes.address }}  -->
          </span>
          <span>Integration</span>
        </div>
        <div class="property-nav" ref="target" @click="toggleOverlay(property.id)">
          <AppIconDotsVertical />
          <PropertyLinks v-if="showOverlay === property.id" :propertyId="property.id" />
        </div>
      </div>
      <div class="bottom-half">
        <div class="metrics">
          <div class="metric-items">
            <div class="metric">
              <AppIconOccupancy :amount="property.attributes.occupancy_rate" />
              {{ property.attributes.occupancy_rate }}
            </div>
            <div class="metric">
              <AppIconExposure :amount="property.attributes.exposure_rate" />
              {{ property.attributes.exposure_rate }}
            </div>
            <!--
            <div class="metric">
              <IconGuestCard color="var(--kyzen-green)" /> ---
            </div>
            -->
          </div>
        </div>
        <div class="sync-status">
          <AppIconSynced />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.icon-enabled {
  color: yellow;
}

.icon-disabled {
  color: red;
}

.property-grid {
  display: inline-grid;
  gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
}

.property-nav {
  width: auto;

  svg.icon-color:hover {
    fill: var(--primary-color);
  }
}

.property-card {
  height: 100%;
  background-color: var(--property-card-bg);
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  border: var(--border-size) solid var(--secondary-color);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    border: var(--border-size) solid var(--primary-color);
  }
}

.top-half {
  display: flex;
  padding: 15px 15px 20px;
  justify-content: space-between;

  .property-text {
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    width: 63%;
    line-height: 17px;

    span {
      display: block;
      font-weight: lighter;
      font-size: 13px;

      &:first-child {
        font-weight: bold;
        padding-bottom: 3px;
        font-size: 18px;
      }

      &:last-child {
        opacity: 0.7;
      }

      &.location {
        font-style: italic;
      }
    }
  }

  .image {
    width: 92px;
    height: 92px;
    border-radius: 13px;
    padding: 5px;
    background: white;
  }

  .property-img {
    position: relative;
    flex-shrink: 0;
    margin-right: 5px;
    border-radius: 13px;
  }

  .property-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--property-img-overlay);
    border-radius: 13px;
  }
}

.bottom-half {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: var(--property-info-bg);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.metrics {
  display: flex;
  justify-content: space-between;

  .metric-items {
    display: flex;
  }
}

.metric {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 8px;
  font-size: 14px;
  color: var(--primary-text-color);
  border-radius: 30px;
  background-color: var(--property-metric-bg);
  padding: 5px 8px;
}

.sync-status {
  color: var(--primary-text-color);
  border-radius: 30px;
  background-color: var(--property-metric-bg);
  padding: 3px 8px;
}
</style>
