<!-- AppMultiSelector.vue -->
<script setup>
// Imports...
import { computed, ref,} from 'vue';
import AppIconAngleDown from "@/components/svg/AppIconAngleDown.vue";
import {onClickOutside} from "@vueuse/core";

// Logic...
const props = defineProps({
  title: {type: String},
  selectedOptions: {type: Array},
  placeholder: {type: String},
});

// Computed text to display the selected option in the header
const selectedOptionText = computed(() => {
  const count = props.selectedOptions.length;
  return count === 0 ? '' : count === 1 ? props.selectedOptions[0].name : `Multiple (${count})`;
});

const showBody = ref(false)

function toggleOpenSelector() {
  showBody.value = !showBody.value
}

const target = ref(null)

onClickOutside(target, () => {
  if (showBody.value) {
    showBody.value = false
  }
})

</script>

<template>
  <div ref="target" >
    <div class="app-multi-selector-container">
      <div class="app-multi-selector-header" @click="toggleOpenSelector">
        <label for="" class="input-label">{{ title }}</label>
        <h5 v-if="selectedOptionText === ''" class="placeholder">
          {{ placeholder }}
          <AppIconAngleDown  class="app-multi-dropdown-icon"/>
        </h5>
        <h5 v-else class="selectedOptionText">
          {{ selectedOptionText }}
          <AppIconAngleDown  class="app-multi-dropdown-icon"/>
        </h5>
      </div>
      <div v-if="showBody" class="app-multi-selector-body">
        <slot name="body">
          <div>No Content to display...</div>
        </slot>
        <div v-if="$slots.footer" class="app-multi-footer-container">
          <slot name="footer"></slot>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-multi-selector-container {
  position: relative;
}

.app-multi-selector-header {
  cursor: pointer;
}

.selectedOptionText, .placeholder {
  font-size: 16px;
  position: relative;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 12px;
  height: 45px;
  line-height: 42px;
  padding: 0 40px 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &.placeholder {
    color: var(--secondary-color);
  }

  &.selectedOptionText {
    color: var(--primary-text-color);
  }

  &:hover {
    border-color: var(--primary-color);
    transition: 0.4s ease;
  }
}

.app-multi-dropdown-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
}

.app-multi-selector-body {
  min-width: 325px;
  margin-top: -10px;
  margin-left: -4px;
  padding: 18px 18px 9px 18px;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--dropdown-bg);
  border-radius: 14px;
  border: var(--border-size-bar) solid var(--secondary-color);
  box-shadow: 0 31px 20px rgba(0, 0, 0, 0.25);

  .search-alt {
    margin: 7px 0 7px 0;
    padding: 7px 18px 0 18px;
  }
}

.app-multi-footer-container {
  border-top: var(--border-size) solid var(--secondary-color);
  margin-left: -18px; /* match the negative margin to the body's padding */
  margin-right: -18px; /* apply to right as well */
  margin-top: 5px;
  padding-left: 18px; /* reapply padding to maintain visual spacing inside the footer */
  padding-right: 18px; /* reapply right padding */
  padding-top: 8px;
}

.input-label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
  padding-left: 16px;
}

// Adjusting this label color for modals
.modal-content {
  .input-label {
    color: var(--primary-text-color);
  }
}
</style>
