<!-- TheDashboardSearch.vue -->
<script setup>
import { ref } from 'vue';
import AppIconClose from '@/components/svg/AppIconClose.vue';
import AppIconSearchSmall from '@/components/svg/AppIconSearchSmall.vue';
import AppFilterIcon from '@/components/svg/AppFilterIcon.vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  placeholder: String,
  label: String,
  modelValue: String,
});

const emit = defineEmits(['update:modelValue', 'filter-changed']);
const target = ref(null);
const isDropdownOpen = ref(false);
const filterOptions = [
  'View all',
  'Google Ads',
  'Google Business Profile',
  'Google Analytics 4',
  'Meta Ads',
  'Reputation',
  'Leasing',
  'Availability',
  'Other',
]; // add more options here as needed
const selectedFilter = ref('');

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const handleSelectFilter = (option) => {
  selectedFilter.value = option;
  emit('filter-changed', option);
  isDropdownOpen.value = false;
};

onClickOutside(target, () => {
  if (isDropdownOpen.value) {
    isDropdownOpen.value = false;
  }
});

const clearInput = () => {
  emit('update:modelValue', '');
};
</script>

<template>
  <div class="search-container">
    <form>
      <div class="input-wrapper">
        <AppIconSearchSmall class="search-icon" />
        <input
          type="search"
          :value="props.modelValue"
          :placeholder="props.placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          autocomplete="off"
          class="search-input"
        />
        <AppIconClose v-if="props.modelValue" @click="clearInput" class="clear-icon" />
        <div ref="target" class="filter-container">
          <AppFilterIcon class="filter-icon" @click="toggleDropdown" />
          <div v-show="isDropdownOpen" class="dropdown-menu">
            <div v-for="(option, index) in filterOptions" :key="index" @click="handleSelectFilter(option)" class="dropdown-option">
              {{ option }}
              <div v-show="selectedFilter === option" class="selected-indicator"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.search-container {
  border-radius: 12px;
  border: 0px solid var(--secondary-color);
}

.input-wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  padding: 0 10px; // Padding for internal spacing
  gap: 5px; // Space between elements
}

.search-icon,
.clear-icon,
.filter-icon {
  background: none;
  border: none;
}

.clear-icon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.filter-container {
  cursor: pointer;
  border-left: 0px solid var(--secondary-color-muted);
  align-self: stretch; // This will make the filter-container stretch to fill the container.
  display: flex;
  align-items: center; // Centers the filter icon vertically.
  justify-content: center; // Centers the filter icon horizontally.
  padding-left: 10px; // Padding for internal spacing
}

.search-input {
  width: 100%;
  border: none;
  height: 44px;
  padding: 0;
  background: transparent;
  color: var(--primary-text-color);
  border-radius: 12px; // Maintain border radius if desired

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--secondary-color);
  }
}

.dropdown-menu {
  position: absolute;
  top: 270px;
  right: 10px;
  width: 250px;
  background: var(--dropdown-bg);
  border-radius: 14px;
  padding: 16px;
  z-index: 10;
}

.dropdown-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 7px;

  &:hover {
    opacity: 1;
    transition: background 0.4s;
    background: linear-gradient(90deg, var(--property-metric-bg) 85%, var(--dropdown-bg) 100%);
  }
}

.selected-indicator {
  width: 10px;
  height: 10px;
  background: var(--primary-color);
  border-radius: 50%;
}
</style>
