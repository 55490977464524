<!-- DashboardPageHeader.vue -->
<script setup>
// Imports...
import { ref } from 'vue';
import AppIconEdit from '@/components/svg/AppIconEdit.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { useDashboardsStore } from '@/stores/DashboardsStore';

// Logic...

// Options Menu button for the sidebar
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked() {
  isOverlayVisible.value = false;
}

const dashboardsStore = useDashboardsStore();

const menuOptions = ['share-link', 'export-to-pdf', 'make-recurring', 'duplicate', 'delete'];
</script>

<template>
  <div v-if="dashboardsStore.selectedDashboard && dashboardsStore.layoutLoaded">
    <div class="styles_pageHeader__section">
      <div class="styles_pageHeader__container">
        <!-- Left side of page header -->
        <div class="left-side-container">
          <h3 v-if="!dashboardsStore.isEditMode">{{ dashboardsStore.dashboardData.name }}</h3>
          <input v-else v-model="dashboardsStore.dashboardData.name" type="text" class="dashboard-input" />
        </div>
        <!-- Right side of page header -->
        <div class="styles_pageHeader__icon-container">
          <div class="top-section" v-if="dashboardsStore.isEditMode">
            <AppButton outlined_btn @click="dashboardsStore.updateDashboardAndOptions"> Save </AppButton>
          </div>
          <div ref="target">
            <AppIconBoxWrapper @click="toggleOverlay">
              <AppIconDotsVertical />
            </AppIconBoxWrapper>
            <AppOverlayMenu v-if="isOverlayVisible" @option-clicked="handleOptionClicked" :options="menuOptions" bodyWidth="150px">
              <template #share-link>
                <div>Share link</div>
              </template>
              <template #export-to-pdf>
                <div>Export to PDF</div>
              </template>
              <template #make-recurring>
                <div>Make recurring</div>
              </template>
              <template #duplicate>
                <div>Duplicate</div>
              </template>
              <template #delete>
                <div @click="dashboardsStore.removeDashboard">Delete</div>
              </template>
            </AppOverlayMenu>
          </div>
          <AppIconBoxWrapper @click="dashboardsStore.toggleEditMode">
            <AppIconEdit />
          </AppIconBoxWrapper>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="styles_pageHeader__section">
      <div class="styles_pageHeader__container">
        <div class="left-side-container">
          <h3>Dashboards</h3>
        </div>
        <!-- Right side of page header -->
        <div class="styles_pageHeader__icon-container">
          <div>
            <AppIconBoxWrapper>
              <AppIconAddAlt />
            </AppIconBoxWrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.left-side-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  :deep(.btn--outline) {
    padding: 5px 30px;
    min-width: 100px;
    min-height: 32px;
    border-color: var(--primary-color);
    border-radius: 5px;
  }
}

.hide-trash-till-dragged {
  display: none;
}

.delete-zone {
  padding: 5px 30px;
  width: 100px;
  height: 30px;
  border: var(--border-size) dashed var(--secondary-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: copy;
}

.icon-trash-can {
  width: 21px;
  height: 21px;
}

.dashboard-input {
  outline: none;
  padding: 0 10px 0 0;
  border-style: none;
  font-size: 19px;
  font-weight: 600;
  color: var(--primary-text-color);
  background-color: transparent;
  min-width: 260px;

  &:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 2px 1px -1px var(--primary-color);
  }
}
</style>
