<!-- SettingsIntegrationCard.vue -->
<script setup>
// Imports...
import { defineAsyncComponent, markRaw, reactive } from 'vue';
import AppIconSynced from '@/components/svg/AppIconSynced.vue';
import { useModalStore } from '@/stores/ModalStore';
// Logic...
const AppIconGoogleAnalytics = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconGoogleAnalytics.vue')));
const AppIconGoogleAds = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconGoogleAds.vue')));
const AppIconGoogleBusinessProfile = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconGoogleBusinessProfile.vue')));
const AppIconGoogleTagManager = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconGoogleTagManager.vue')));
const AppIconFacebookAds = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconFacebook.vue')));
const AppIconKyzen = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconKyzen.vue')));
const AppIconSlack = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconSlack.vue')));
const AppIconChatMeter = markRaw(defineAsyncComponent(() => import('@/components/svg/AppIconChatMeter.vue')));

const integrations = reactive([
  {
    integration_icon: AppIconGoogleAnalytics,
    integration_name: 'Google Analytics 4',
    integration_description: 'Google Analytics is the next generation of Analytics which collects event-based data from both websites and apps.',
    integration_sync_status: true,
    last_edited: 'Mar 8, 2024',
    tag_id: 'GTM-85A8T2C',
    id: 1,
  },
  {
    integration_icon: AppIconGoogleAds,
    integration_name: 'Google Ads',
    integration_description:
      'Google Ads is a product that can help you promote your business, sell items or services, raise awareness, and drive visitors to your site. Set your budget and start measuring the impact of your ad.',
    integration_sync_status: false,
    last_edited: 'Mar 8, 2024',
    id: 2,
  },
  {
    integration_icon: AppIconGoogleBusinessProfile,
    integration_name: 'Google Business Profile',
    integration_description:
      'Your Business Profile is a free tool that allows you to take charge of the way your business appears on Google Search and Maps.',
    integration_sync_status: false,
    last_edited: 'Mar 8, 2024',
    id: 3,
  },
  {
    integration_icon: AppIconGoogleTagManager,
    integration_name: 'Google Tag Manager',
    integration_description:
      'Google Tag Manager is a tag management system that has the same functionality as the Google tag and lets you configure and instantly deploy tags on your website or mobile app from an easy-to-use web-based interface.',
    integration_sync_status: false,
    last_edited: 'Mar 8, 2024',
    tag_id: 'GTM-85A8T2C',
    id: 4,
  },
  {
    integration_icon: AppIconFacebookAds,
    integration_name: 'Facebook Ads',
    integration_description: 'Connect your Facebook ad account to Kyzen to track and manager your ads.',
    integration_sync_status: false,
    last_edited: 'Mar 8, 2024',
    id: 5,
  },
  {
    integration_icon: AppIconKyzen,
    integration_name: 'Kyzen API',
    integration_description:
      'Portfolio and per property budget reporting, spend recommendations, and accurate cost-per-lease reporting are all at your fingertips.',
    integration_sync_status: false,
    last_edited: 'Mar 8, 2024',
    id: 6,
  },
  {
    integration_icon: AppIconSlack,
    integration_name: 'Slack',
    integration_description: 'Automatically connect to Slack to receive notifications.',
    integration_sync_status: false,
    last_edited: 'Mar 8, 2024',
    id: 7,
  },
  {
    integration_icon: AppIconChatMeter,
    integration_name: 'Chatmeter API',
    integration_description:
      'Chatmeter API allows businesses to manage their online presence and reputation across various locations by providing centralized SEO, review management, and listing accuracy insights.',
    integration_sync_status: false,
    last_edited: 'Mar 19, 2024',
    id: 8,
  },
]);

const modalStore = useModalStore();

function handleCardClick(integration) {
  const modalId = integration.integration_sync_status ? `synced${integration.integration_name.replace(/\s/g, '')}Modal` : 'settingsNotSyncedModal';
  modalStore.openModal(modalId, integration);
}
</script>

<template>
  <div class="integration-grid">
    <!-- Iterate over integrations and display each one in the card component -->
    <div class="integration-block" v-for="(integration, index) in integrations" :key="index" @click="handleCardClick(integration)">
      <div class="integration-card">
        <div class="integration-card__header">
          <div class="integration-icon">
            <component :is="integration.integration_icon" />
          </div>
          <h3 class="integration-name">
            {{ integration.integration_name }}
            <span class="last-edit">{{ integration.last_edited }}</span>
          </h3>
        </div>
        <div class="integration-card__body">
          <p class="integration-description">{{ integration.integration_description }}</p>
        </div>
        <div class="integration-card__footer">
          <span class="status" :class="{ synced: integration.integration_sync_status }">
            <AppIconSynced />
            {{ integration.integration_sync_status ? 'Synced' : 'Not Synced' }}
          </span>
          <span class="tag-id">{{ integration.tag_id }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.integration-grid {
  display: inline-grid;
  gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
}

.integration-block {
  border-radius: 24px;
}

.integration-card {
  color: #fff;
  height: 258px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  background-color: var(--property-card-bg);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    border-color: var(--primary-color);
  }
}

.integration-card__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 20px 20px 0;
}

.integration-card__body {
  flex-grow: 1;
  padding: 20px;
}

.integration-icon {
  background-color: var(--element-bg);
  width: 70px;
  height: 70px;
  border-radius: 50%;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    padding: 16px;
  }
}

.integration-name {
  font-family: Lato, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--primary-text-color);
}

.last-edit {
  display: block;
  font-family: Lato, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  color: var(--secondary-color);
}

.integration-description {
  color: var(--primary-text-color);
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.status {
  color: var(--secondary-color);
  font-family: Lato, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;

  &.synced {
    color: var(--secondary-color);
  }
}

.tag-id {
  color: var(--primary-text-color);
  text-align: center;
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.925px;
  /* 191.045% */
  letter-spacing: -0.228px;
}

.integration-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--element-bg);
  border-top: 1px solid var(--border-color);
  padding: 10px 20px;
  border-radius: 0 0 20px 20px;
  height: 60px;
}
</style>
