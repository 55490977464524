<script setup>
// imports
import { onMounted, watch } from 'vue';
import PropertiesCard from '@/components/properties/DetailedLayout.vue';
import { usePropertiesStore } from '@/stores/PropertiesStore';
import PropertiesTable from '@/components/properties/TableLayout.vue';
import PropertiesManageGroupsModal from '@/components/ui/modals/PropertiesManageGroupsModal.vue';
import PropertiesNewGroupsModal from '@/components/ui/modals/PropertiesNewGroupsModal.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import { useScrollVisibility } from '@/composables/useScrollVisibility';
import { useModalStore } from '@/stores/ModalStore';
import AppSpinner from '@/components/ui/AppSpinner.vue';

// Logic ***********************

// Store instances
const propertiesStore = usePropertiesStore();
const globalStore = useGlobalStore();
const modalStore = useModalStore();

useScrollVisibility(globalStore, 'Properties');

// Fetch properties on component mount
onMounted(async () => {
  await propertiesStore.getProperties(); // This will update the originalProperties state
  propertiesStore.currentPropertyView = localStorage.getItem('currentPropertyView') || 'grid';
});

watch(
  () => propertiesStore.searchQuery,
  () => {
    propertiesStore.applyFilters();
  }
);
</script>

<template>
  <div v-if="propertiesStore.properties.length === 0">
    <!-- Show loading spinner while data is loading -->
    <div v-if="propertiesStore.isLoading" class="center-spinner">
      <AppSpinner />
    </div>
  </div>
  <div class="styles_view__body">
    <!-- Grid View -->
    <PropertiesCard v-if="propertiesStore.currentPropertyView === 'grid'" />
    <!-- List/Table View -->
    <PropertiesTable v-if="propertiesStore.currentPropertyView === 'list'" />
  </div>
  <!--  Modals Start -->
  <PropertiesManageGroupsModal v-if="modalStore.isModalOpen('manageGroupsModal')" />
  <PropertiesNewGroupsModal v-if="modalStore.isModalOpen('newGroupsModal')" />
  <!--  Modals End -->
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: auto;
}

.flex-basis {
  flex-basis: content;
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
</style>
