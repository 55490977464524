
export const reportOptionsData =  [
				{
					label: 'Budget vs Actual',
					checked: false,
					value: 'budgetVsActual'
				},
				{
					label: 'Cost-Per-Lease',
					checked: false,
					value: 'costPerLease'
				},
				{
					label: 'Dashboard',
					checked: false,
					value: 'dashboard'
				},
				{
					label: 'ETA',
					checked: false,
					value: 'eta'
				},
				{
					label: 'Extension',
					checked: false,
					value: 'extension'
				},
				{
					label: 'Keyword',
					checked: false,
					value: 'keyword'
				},
				{
					label: 'RSA',
					checked: false,
					value: 'rsa'
				},
				{
					label: 'Source Attribution',
					checked: false,
					value: 'attribution'
				},
				{
					label: 'Source Group',
					checked: false,
					value: 'sourceGroup'
				},
				{
					label: 'Trending',
					checked: false,
					value: 'trending'
				},
				
				
				
				
				
			]