import { defineStore } from 'pinia';

export const useModalStore = defineStore('ModalStore', {
  state: () => ({
    currentModal: null,
    modalData: {}
  }),

  getters: {
    isModalOpen: (state) => (modalId) => {
      return state.currentModal === modalId;
    }
  },

  actions: {
    openModal(modalId, data = {}) {
      this.currentModal = modalId;
      this.modalData = data;  // Set the data when the modal is opened
    },
    closeModal(modalId) {
      if (this.currentModal === modalId) {
        this.currentModal = null;
        this.modalData = {};  // Clear the data when the modal is closed

      } else {
        console.error(`Modal with ID ${modalId} is not open.`);
      }
    },
  }
});
